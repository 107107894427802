<template>
  <b-form class="rental-period-cart">
    <b-row>
      <b-col sm="12" @keypress="preventInput">
        <label
          >Huurperiode <small>Aantal huurperiodes {{ periods }}</small></label
        >
        <date-range-picker
          v-model="dateRange"
          class="w-100"
          :min-date="minDate"
          :locale-data="locale"
          :date-format="dateFormat"
          :max-date="maxDate"
          :ranges="false"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import Vue from 'vue'
import dayjs from 'dayjs'
import DateRangePicker from '@rentmagic/vue2-daterange-picker'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import '@rentmagic/vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { getClosedDates } from '@/services/WarehouseService'
import nlLocale from '@/locales/nl-calendar.json'
import { mapGetters } from 'vuex'

dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

export default {
  name: 'RentalPeriod',
  components: {
    DateRangePicker
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      bus: new Vue(),
      disabled: [0, 6],
      position: 'left',
      format: 'dd/MM/yyyy',
      clear: false,
      lang: 'nl',
      placeholder: 'Selecteer een datum',
      closedDates: []
    }
  },
  computed: {
    locale() {
      return nlLocale
    },
    dateEditable() {
      if (this.updatingItem) return false
      return this.$store.getters.cartCount < 1
    },
    minDate() {
      return dayjs()
        .add(1, 'days')
        .toDate()
    },

    maxDate: function() {
      return dayjs()
        .add(6, 'months')
        .toDate()
    },
    periods() {
      var periodAmount = Math.floor(
        (dayjs(this.dateRange.endDate).diff(this.dateRange.startDate, 'days') +
          1) /
          7
      )
      var rest =
        (dayjs(this.dateRange.endDate).diff(this.dateRange.startDate, 'days') +
          1) %
        7

      if (rest > 1) {
        periodAmount += 1
      }

      return periodAmount
    },
    ...mapGetters({
      warehouse: 'rentalInformation/warehouse',
      storeStartDate: 'rentalInformation/startDate',
      storeEndDate: 'rentalInformation/endDate'
    })
  },
  watch: {
    warehouse() {
      this.getClosedDates()
    },
    dateRange: {
      handler(val) {
        this.startDate = dayjs(val.startDate).format('YYYY-MM-DD')
        this.endDate = dayjs(val.endDate).format('YYYY-MM-DD')
      },
      deep: true
    },
    startDate() {
      if (this.dateEditable) {
        // if this.endDate is same day or before this.startDate

        if (dayjs(this.startDate).isSameOrAfter(this.endDate)) {
          // this.endDate = dayjs(this.startDate || dayjs(), 'DD/MM/YYYY')
          //   .add(7, 'days')
          //   .format('DD/MM/YYYY')
        }
      }

      this.$store.dispatch(
        'rentalInformation/setStartDate',
        dayjs(this.startDate).format('YYYY-MM-DD')
      )
    },
    endDate() {
      this.getClosedDates()

      this.$store.dispatch(
        'rentalInformation/setEndDate',
        dayjs(this.endDate).format('YYYY-MM-DD')
      )
    }
  },
  async created() {
    if (this.storeStartDate) {
      this.dateRange.startDate = dayjs(this.storeStartDate).toDate()
    }

    if (this.storeEndDate) {
      this.dateRange.endDate = dayjs(this.storeEndDate).toDate()
    }

    if (this.storeStartDate && this.storeEndDate) {
      this.getClosedDates()
    }
  },
  methods: {
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = this.closedDates.includes(
          dayjs(date).format('YYYY-MM-DD')
        )
      }
      return classes
    },
    async getFormattedClosedDays() {
      const closedDates = await getClosedDates()

      return closedDates
    },
    preventInput($event) {
      $event.preventDefault()
    },
    getBus: function() {
      return this.bus
    },
    async getClosedDates() {
      const closedDates = await getClosedDates({
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs(this.$store.getters['rentalInformation/startDate'])
          .add(6, 'months')
          .format('YYYY-MM-DD'),
        stock: this.selectedWarehouse
      })

      this.closedDates = closedDates.map(date => {
        return dayjs(date).format('YYYY-MM-DD')
      })
    }
  }
}
</script>

<style>
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #a73b35;
  border-color: transparent;
  color: #fff;
}
.daterangepicker .btn-primary,
.daterangepicker .btn-success {
  background-color: #a73b35 !important;
  color: #fff;
}
.reportrange-text {
  padding: 0.55rem 3rem 0.55rem 2rem !important;
}
</style>
