<template>
  <b-container class="page-container cart">
    <bread-crumbs currentpage="Winkelmandje" />
    <h1>Winkelmandje</h1>
    <b-alert v-if="addedReservationItems" show variant="danger">
      Artikelen van de reservering zijn toegevoegd aan je winkelmandje.
    </b-alert>
    <b-form
      v-if="this.$store.getters.cartCount > 0"
      @submit.prevent="updateCartAndContinue"
    >
      <b-card class="mb-4">
        <b-row>
          <b-col md="12" lg="12" xl="2">
            <warehouse-selector />
          </b-col>
          <b-col md="12" lg="12" xl="8">
            <b-row>
              <b-col cols="6">
                <rental-period />
              </b-col>
            </b-row>
          </b-col>

          <b-col
            md="12"
            lg="4"
            xl="2"
            style="
    display: flex;
    align-items: flex-end;
"
          >
            <div>
              <b-button
                v-if="mutationProblems.length != 0"
                v-b-modal.modal-1
                class="mt-3"
                style="
    margin-bottom: 7px;
"
                variant="primary"
                :disabled="!isLocationOrDateChanged"
              >
                Bijwerken
                <font-awesome-icon
                  v-if="cartProcessing"
                  class="fa-spin"
                  far
                  :icon="['fad', 'spinner']"
                />
              </b-button>

              <b-modal
                id="modal-1"
                v-model="show"
                :hide-header="true"
                :hide-footer="true"
              >
                <h5>
                  <span class="text-danger">
                    <font-awesome-icon icon="exclamation-circle" />
                  </span>
                  <span class="text-primary">Opgelet!</span>
                </h5>
                <p class="my-4">
                  Als je de afhaallocatie of ophaal/aflever datum wijzigt, heeft
                  dit een effect op alle producten die al in je winkelmandje
                  zitten:
                </p>
                <ul>
                  <li v-for="item in mutationProblems" :key="item.ID">
                    {{ item.Description }}
                  </li>
                </ul>
                <p>
                  Mogelijks zijn de bovenstaande product(en) niet beschikbaar op
                  de nieuwe afhaallocatie. Heel uw aanvraag zal op de nieuwe
                  locatie afgehaald moet worden. Weet je zeker dat je verder
                  wilt gaan?
                </p>
                <div class="text-center">
                  <hr />
                  <b-button
                    class="text-center"
                    variant="primary"
                    @click="updateCart({ modal: true })"
                    >Verdergaan en datum/locatie wijzigen</b-button
                  >
                  <b-button variant="link" @click="show = false">
                    Annuleren en locatie afhaal/ophaal datum niet aanpassen
                  </b-button>
                </div>
              </b-modal>

              <b-modal
                id="modal-1"
                v-model="show"
                :hide-header="true"
                :hide-footer="true"
              >
                <h5>
                  <span class="text-danger">
                    <font-awesome-icon icon="exclamation-circle" />
                  </span>
                  <span class="text-primary">Opgelet!</span>
                </h5>
                <p class="my-4">
                  Als je de afhaallocatie of ophaal/aflever datum wijzigt, heeft
                  dit een effect op alle producten die al in je winkelmandje
                  zitten:
                </p>
                <ul>
                  <li v-for="item in mutationProblems" :key="item.ID">
                    {{ item.Description }}
                  </li>
                </ul>
                <p>
                  Mogelijks zijn de bovenstaande product(en) niet beschikbaar op
                  de nieuwe afhaallocatie. Heel uw aanvraag zal op de nieuwe
                  locatie afgehaald moet worden. Weet je zeker dat je verder
                  wilt gaan?
                </p>
                <div class="text-center">
                  <hr />
                  <b-button
                    class="text-center"
                    variant="primary"
                    @click="updateCart({ modal: true })"
                    >Verdergaan en datum/locatie wijzigen</b-button
                  >
                  <b-button variant="link" @click="show = false">
                    Annuleren en locatie afhaal/ophaal datum niet aanpassen
                  </b-button>
                </div>
              </b-modal>
            </div>
            <b-button
              v-if="mutationProblems.length == 0"
              class="mt-3 btn-block"
              style="
    margin-bottom: 7px;
"
              variant="primary"
              :disabled="!isLocationOrDateChanged"
              @click="updateCart"
            >
              Bijwerken
              <font-awesome-icon
                v-if="cartProcessing"
                class="fa-spin"
                far
                :icon="['fad', 'spinner']"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <div>
        <b-table class="cart" striped hover :items="items" :fields="fields">
          <template v-slot:cell(price)="data">
            <div class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.priceIncVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>

          <template v-slot:cell(amount)="row">
            <div class="form-group amount" :disabled="loading">
              <b-button
                size="sm"
                :disabled="row.item.amount < 2 || loading"
                @click="
                  updateCartQuantity({
                    item: row.item,
                    amount: row.item.amount - 1
                  }) &&
                    row.item.amount-- &&
                    row.maxAdded++
                "
                ><font-awesome-icon far :icon="['fa', 'minus']"
              /></b-button>

              <span class="pl-2 pr-2">{{ row.item.amount }}</span>
              <b-button
                size="sm"
                :disabled="
                  row.item.maxPerCustomerLeftover <= 0 ||
                    row.item.maxPerCustomer === row.item.amount ||
                    row.item.maxPerCustomer < row.item.amount ||
                    loading
                "
                @click="
                  updateCartQuantity({
                    item: row.item,
                    amount: row.item.amount + 1
                  }) &&
                    row.item.maxAdded-- &&
                    row.item.amount++
                "
                ><font-awesome-icon far :icon="['fa', 'plus']"
              /></b-button>
            </div>
          </template>

          <template v-slot:cell(delete)="data">
            <div class="form-group">
              <a href="#" @click="deleteCartItem(data.value)">Verwijder</a>
            </div>
          </template>
          <template v-slot:cell(total)="data">
            <div class="form-group text-right">
              &euro;
              {{
                (Math.round(data.item.totalIncVAT * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col cols="10" class="text-right cart-total-price">
            <p>
              <strong>BTW:</strong>
            </p>
          </b-col>
          <b-col cols="2" class="text-right">
            <p>
              &euro;
              {{
                cartCache.TotalVAT.toFixed(2)
                  .toString()
                  .replace('.', ',')
              }}
            </p>
          </b-col>
          <b-col cols="10" class="text-right cart-total-price">
            <p>
              <strong>Totaalbedrag:</strong>
            </p>
          </b-col>
          <b-col cols="2" class="text-right">
            <p>
              &euro;
              {{
                cartCache.TotalIncVAT.toFixed(2)
                  .toString()
                  .replace('.', ',')
              }}
            </p>
          </b-col>
        </b-row>
      </div>
      <b-card class="mb-4 mt-2 text-right">
        <b-button
          v-if="mutationProblems.length != 0"
          v-b-modal.modal-1
          class="float-right"
          variant="primary"
          :disabled="buttonsDisabled"
        >
          Aanvraag voltooien
          <font-awesome-icon
            v-if="cartProcessing"
            class="fa-spin"
            far
            :icon="['fad', 'spinner']"
          />
        </b-button>
        <b-button
          v-if="mutationProblems.length == 0"
          type="submit"
          class="float-right"
          variant="primary"
          :disabled="buttonsDisabled"
        >
          Aanvraag voltooien
          <font-awesome-icon
            v-if="cartProcessing"
            class="fa-spin"
            far
            :icon="['fad', 'spinner']"
          />
        </b-button>
        <a href="/" class="btn btn-link shop-more">
          <font-awesome-icon fas icon="chevron-left" /> Verder winkelen</a
        >
      </b-card>
    </b-form>
    <b-alert v-if="this.$store.getters.cartCount < 1" show variant="warning">
      <font-awesome-icon icon="exclamation-circle" />Opgelet: U heeft nog geen
      artikelen in uw winkelwagen, voeg eerst een artikel toe aan uw
      winkelwagen!
    </b-alert>
  </b-container>
</template>
<script>
// @ is an alias to /src
import {
  getCart,
  deleteCartItem,
  updateCartItem,
  updateCart,
  cartMutationCheck
} from '@/services/CartService'

import { mapGetters } from 'vuex'
import WarehouseSelector from '@/components/warehouse/WarehouseSelector'
import RentalPeriod from '@/components/rental-period/RentalPeriodCart'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export default {
  name: 'Cart',
  components: {
    RentalPeriod,
    WarehouseSelector
  },
  metaInfo: {
    title: `Winkelmandje`
  },
  data() {
    return {
      fields: [
        {
          key: 'item',
          label: 'Artikel'
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'composition',
          class: 'd-none',
          hidden: true
        },
        {
          key: 'price',
          label: 'Prijs',
          class: 'text-right'
        },
        {
          key: 'amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'delete',
          label: ''
        },
        {
          key: 'total',
          label: 'Totaal',
          class: 'text-right'
        }
      ],
      items: [],
      buttonsDisabled: true,
      clear: false,
      show: false,
      lang: 'nl',
      cartProcessing: false,
      isLocationOrDateChanged: true,
      loading: true,
      mutationProblems: []
    }
  },
  computed: {
    ...mapGetters({
      cartCache: 'cartCache',
      startDate: 'rentalInformation/startDate',
      endDate: 'rentalInformation/endDate',
      warehouse: 'rentalInformation/warehouse'
    }),

    addedReservationItems: function() {
      return this.$route.query.addedReservationItems
    }
  },
  watch: {
    warehouse() {
      this.updateCartMutationProblems()
    },
    endDate() {
      this.updateCartMutationProblems()
    }
  },
  async beforeCreate() {
    this.cartProcessing = true

    let cart = this.$store.state.cartCache.CartItems.Collection

    this.items = cart
    this.items = await getCart({ warehouse: this.warehouse })

    await this.updateCartMutationProblems()

    this.loading = false
  },
  methods: {
    refreshCart: async function() {
      this.loading = true
      this.cartProcessing = true

      this.items = await getCart({ warehouse: this.warehouse })

      this.loading = false
    },
    deleteCartItem: async function(cartItemID) {
      deleteCartItem({ cartItemID })
      this.items = await getCart({ warehouse: this.warehouse })
    },
    updateCartMutationProblems: async function() {
      this.buttonsDisabled = true
      this.mutationProblems = await cartMutationCheck({
        warehouseID: this.warehouse,
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.buttonsDisabled = false
    },
    hideModal: function() {
      this.show = false
    },
    updateCartQuantity: async function({ item, amount }) {
      this.loading = true
      const result = await updateCartItem({
        cartItemID: item.cartItemID,
        amount
      })

      await this.updateCartMutationProblems()

      if (result) {
        const cartItems = await getCart({ warehouse: this.warehouse })
        this.items = cartItems
      } else {
        alert('Dit artikel is niet voorradig')
      }
      this.loading = false
    },
    async updateCart(event) {
      if (!event.modal) {
        event.preventDefault()
      }

      this.cartProcessing = true
      await this.updateCartMutationProblems()

      if (this.mutationProblems.length > 0 && !event.modal) {
        this.show = true
        this.cartProcessing = false
        return
      }

      if (
        dayjs(this.retrievalDate, 'DD-MM-YYYY').format('X') >
        dayjs(this.returnDate, 'DD-MM-YYYY').format('X')
      ) {
        this.cartProcessing = false
      } else {
        await updateCart({
          warehouseID: this.warehouse,
          startDate: this.startDate,
          endDate: this.endDate
        })
      }

      this.items = await getCart({ warehouse: this.warehouse })

      this.show = false

      this.cartProcessing = false
      this.isLocationOrDateChanged = true
    },

    async updateCartAndContinue(event) {
      event.preventDefault()
      this.cartProcessing = true
      await this.updateCartMutationProblems()

      if (dayjs(this.startDate).format('X') > dayjs(this.endDate).format('X')) {
        this.cartProcessing = false
        await alert('De afhaaldatum is later dan de terugbrengdatum')
        return
      } else {
        await updateCart({
          warehouseID: this.warehouse,
          startDate: this.startDate,
          endDate: this.endDate
        })

        this.$router.push({ name: 'confirmation' })
      }
    }
  }
}
</script>
<style lang="scss">
.shop-more {
  svg {
    font-size: 13px;
  }
}
.label-padding {
  margin-top: 32px;
}
.cart-total-price {
  p {
    font-size: 18px;
    font-weight: 600;
    font-family: 'DecimaPro-bold' !important;
  }
}
.datepicker-input {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  height: 38px;
}

.warning {
  color: #d3732f;
}
.cart .form-group {
  margin-bottom: 0px;
}
</style>
